import * as React from 'react';
import './SearchResultStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import SearchResult from './SearchResult';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { localStorageHelper } from 'utils';
import { Loader } from 'Components/Loader/Loader';
import { Empty } from 'antd';
import { ISearchPlayersData } from 'helpers/interfaces';
import { removeSearchFromStorage } from 'redux/actions/backoffice/search-actions';
import {
    getSearchResult,
    getSearchStatus,
    getSearchString,
    getSearchError,
} from 'redux/selectors/backoffice/search-selectors';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';

interface IProps {
    searchResult: ISearchPlayersData[];
    searchString: string;
    isSearching: boolean;
    removeSearchFromStorage: Function;
    error: boolean;
}

interface IState {
    fromDate: Date;
    toDate: Date;
}

class SearchResultContainer extends React.Component<WithTranslation & IProps, IState> {
    getContent = () => {
        const { searchResult, searchString, isSearching, error, t } = this.props;
        const content =
            searchResult || localStorageHelper.getChainedValue('user.search.searchResult');
        const string =
            searchString || localStorageHelper.getChainedValue('user.search.currentSearchString');

        if (error) {
            return <ErrorComponent error={t('server_error')} />;
        }

        if (string) {
            if (!isSearching) {
                if (content && content.length > 0) {
                    return <SearchResult players={content} />;
                } else {
                    return <Empty description={t('search_c_find_anything')} />;
                }
            } else {
                return <Loader />;
            }
        } else {
            return <div />;
        }
    };

    getTitle = () => {
        const { searchResult, searchString, isSearching, error, t } = this.props;
        const content =
            searchResult || localStorageHelper.getChainedValue('user.search.searchResult');
        const string =
            searchString || localStorageHelper.getChainedValue('user.search.currentSearchString');

        if (error) {
            return;
        }

        if (string) {
            if (!isSearching) {
                return `${t('search_results_found')} ${content.length}`;
            } else {
                return '';
            }
        } else {
            return t('search_enter_a_name');
        }
    };

    render = () => {
        const { t } = this.props;

        return (
            <ContentComponent
                customBreadcrumbs={<div>{t('search')}</div>}
                innerContent={this.getContent()}
                title={this.getTitle()}
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    searchResult: getSearchResult(state),
    searchString: getSearchString(state),
    isSearching: getSearchStatus(state),
    error: getSearchError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    removeSearchFromStorage: () => dispatch(removeSearchFromStorage()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(SearchResultContainer));
