import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { DesktopOutlined, InfoOutlined, MobileOutlined, TabletOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FreeRoundType, localStorageHelper } from 'utils';

enum CampaignStatus {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    ON_SIMULATION = 'ON_SIMULATION',
    PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
    EXPIRED = 'EXPIRED',
    FINISHED = 'FINISHED',
}
const stringToInteger = (numberString: any) => {
    if (numberString === null) {
        return -1;
    }

    const string = numberString.toString();
    const numberWithoutCommas = string.replace(/,/g, '');
    const number = parseFloat(numberWithoutCommas);

    if (isNaN(number)) {
        return 0;
    }

    return number;
};

function createSorter(property: string) {
    return (a: { [key: string]: any }, b: { [key: string]: any }): number => {
        return stringToInteger(a[property]) - stringToInteger(b[property]);
    };
}

export const PlayerReportColumns = (
    activeCurrencyCode: string,
    getGameHistoryData: Function,
    playerId: any,
    playerRawData: any,
) => {
    const { t } = useTranslation();
    const isPrimaryFlow =
        localStorageHelper.getChainedValue('user.brandFeatures.freeRoundsType') ===
        FreeRoundType.PRIMARY_FLOW;

    return [
        {
            title: t('game_name'),
            dataIndex: 'game_name_with_id',
            key: 'game_name',
            sorter: createSorter('game_name_with_id'),
            width: 185,
            fixed: true,
            render: (test: any, record: any) => (
                <span>{record.gameName + ' | ID: ' + record.gameId}</span>
            ),
        },
        {
            title: t('roundId'),
            dataIndex: 'roundId',
            key: 'roundId',
            width: 185,
        },
        {
            title: t('game_session_id'),
            dataIndex: 'gameSessionId',
            key: 'game_session_id',
            sorter: createSorter('gameSessionId'),
            width: 165,
        },
        {
            title: t('country_city'),
            dataIndex: 'country',
            key: 'country',
            sorter: createSorter('country'),
            width: 165,
            render: (text: string, record: any) => (
                <span>{text ? `${text} | ${record.city}` : t('unknown')}</span>
            ),
        },
        {
            title: t('played_at'),
            dataIndex: 'endTime',
            key: 'played_at',
            render: (text: any) => <span>{text}</span>,
            sorter: (a: any, b: any) =>
                new Date(a.endTime).getTime() - new Date(b.endTime).getTime(),
            width: 165,
        },
        {
            title: t('device_type'),
            dataIndex: 'deviceType',
            key: 'device_type',
            render: (text: string) => {
                if (text === 'desktop') return <DesktopOutlined className="device-type" />;
                if (text === 'tablet') return <TabletOutlined className="device-type" />;
                else return <MobileOutlined className="device-type" />;
            },
            width: 90,
        },
        {
            title: t('type'),
            dataIndex: 'type',
            key: 'type',
            width: 165,
        },
        {
            title: t('Status'),
            dataIndex: 'finished',
            key: 'Status',
            width: 165,
            render: (value: boolean) => <span>{value ? t('finished') : t('unfinished')}</span>,
        },
        {
            title: isPrimaryFlow ? (
                t('promotion')
            ) : (
                <div style={{ display: 'flex' }}>
                    {t('promotion')}{' '}
                    <Tooltip title={t('promotion_highlight_tooltip')}>
                        <InfoOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'promotionId',
            key: 'promotion',
            width: 165,
            className: 'campaign-status-column',
            render: (text: string, record: any) => {
                const { login, currencyId, promotionId, gameSessionId } = record;
                const queryParams = `promotion-${playerId}+${promotionId}+${gameSessionId}+${currencyId}+${login}`;

                return text ? (
                    <div
                        className={
                            !isPrimaryFlow && record.status !== CampaignStatus.FINISHED
                                ? 'marked-campaign'
                                : ''
                        }
                    >
                        <Link className="login" to={`/casino/promotion/${queryParams}`}>
                            {text}
                        </Link>
                    </div>
                ) : (
                    <span>{t('n_a')}</span>
                );
            },
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'bet',
            key: 'bet',
            sorter: createSorter('bet'),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'win',
            key: 'win',
            sorter: createSorter('win'),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'net',
            key: 'net',
            sorter: createSorter('net'),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('start_balance')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'startBalance',
            key: 'start_balance',
            sorter: createSorter('startBalance'),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('end_balance')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'balance',
            key: 'end_balance',
            sorter: createSorter('balance'),
            width: 165,
        },
        {
            title: t('visual_log'),
            dataIndex: 'callback',
            key: 'visual_log',
            render: (_text: string, record: any) => {
                const rawData = playerRawData.find((data: any) => data.roundId === record.roundId);
                const playerData = { ...rawData, playerId };

                const onClick = () => {
                    getGameHistoryData(playerData);
                };
                return <Button onClick={onClick}>Visual log</Button>;
            },
            width: 165,
        },
    ];
};
