import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyUtils, dateFormatter } from '../../../utils';
import { currencyNameConverter } from '../../../utils/Currency/currencyNameConverter';

const stringToInteger = (numberString: any) => {
    if (numberString === null) {
        return -1;
    }

    const string = numberString.toString();
    const numberWithoutCommas = string.replace(/,/g, '');
    const number = parseFloat(numberWithoutCommas);

    if (isNaN(number)) {
        return 0;
    }

    return number;
};

function createSorter(property: string) {
    return (a: { [key: string]: any }, b: { [key: string]: any }): number => {
        return stringToInteger(a[property]) - stringToInteger(b[property]);
    };
}

const CasinoColumns = (activeCurrencyCode: string) => {
    const { t } = useTranslation();

    return [
        {
            title: t('casino_report_from_date'),
            dataIndex: 'from',
            key: 'from',
            render: (text: any) => <span>{dateFormatter.getFormattedForReportDate(text)}</span>,
            width: 165,
            fixed: true,
            sorter: (a: { from: Date | string }, b: { from: Date | string }): number => {
                const timestampA = new Date(a.from).getTime();
                const timestampB = new Date(b.from).getTime();

                return timestampA - timestampB;
            },
        },
        {
            title: t('casino_report_to_date'),
            dataIndex: 'to',
            key: 'to',
            render: (text: any) => <span>{dateFormatter.getFormattedForReportDate(text)}</span>,
            width: 165,
            fixed: true,
            sorter: (a: { to: Date | string }, b: { to: Date | string }): number => {
                const timestampA = new Date(a.to).getTime();
                const timestampB = new Date(b.to).getTime();

                return timestampA - timestampB;
            },
        },
        {
            title: t('spins'),
            dataIndex: 'spins',
            key: 'spins',
            width: 165,
            sorter: createSorter('spins'),
        },
        {
            title: t('currency_filter'),
            dataIndex: 'currencyId',
            key: 'currencyId',
            render: (text: any) =>
                text === 0 ? t('all_consolidated') : currencyNameConverter.idToCode(`${text}`),
            width: 165,
            sorter: (a: any, b: any): any => {
                const aCurrencyCode =
                    currencyUtils.getCurrencyDataById(a.currencyId)?.code || 'all';
                const bCurrencyCode =
                    currencyUtils.getCurrencyDataById(b.currencyId)?.code || 'all';

                return aCurrencyCode.localeCompare(bCurrencyCode);
            },
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'bet',
            key: 'bet',
            width: 165,
            sorter: createSorter('bet'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'win',
            key: 'win',
            width: 165,
            sorter: createSorter('win'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'net',
            key: 'net',
            width: 165,
            sorter: createSorter('net'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('rtp')}
                    <span>{'%'}</span>
                </span>
            ),
            dataIndex: 'rtp',
            key: 'rtp',
            width: 165,
            sorter: createSorter('rtp'),
        },
        {
            title: t('players'),
            dataIndex: 'players',
            key: 'players',
            width: 165,
            sorter: createSorter('players'),
        },
        {
            title: t('newPlayers'),
            dataIndex: 'newPlayers',
            key: 'newPlayers',
            width: 165,
            sorter: createSorter('newPlayers'),
        },
        {
            title: t('free_round_spins'),
            dataIndex: 'freeRoundsSpins',
            key: 'freeRoundsSpins',
            width: 175,
            sorter: createSorter('freeRoundsSpins'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsBet',
            key: 'freeRoundsBet',
            width: 175,
            sorter: createSorter('freeRoundsBet'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsNet',
            key: 'freeRoundsNet',
            width: 175,
            sorter: createSorter('freeRoundsNet'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_rtp')}
                    <span>{'%'}</span>
                </span>
            ),
            dataIndex: 'freeRoundsRtp',
            key: 'freeRoundsRtp',
            width: 175,
            sorter: createSorter('freeRoundsRtp'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsWin',
            key: 'freeRoundsWin',
            width: 175,
            sorter: createSorter('freeRoundsWin'),
        },
        {
            title: t('buy_feature_spins'),
            dataIndex: 'buyFeatureSpins',
            key: 'buyFeatureSpins',
            width: 175,
            sorter: createSorter('buyFeatureSpins'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureBet',
            key: 'buyFeatureBet',
            width: 175,
            sorter: createSorter('buyFeatureBet'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureNet',
            key: 'buyFeatureNet',
            width: 175,
            sorter: createSorter('buyFeatureNet'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_rtp')}
                    <span>{'%'}</span>
                </span>
            ),
            dataIndex: 'buyFeatureRtp',
            key: 'buyFeatureRtp',
            width: 175,
            sorter: createSorter('buyFeatureRtp'),
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureWin',
            key: 'buyFeatureWin',
            width: 175,
            sorter: createSorter('buyFeatureWin'),
        },
    ];
};

export default CasinoColumns;
