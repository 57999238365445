import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import '../PromotionsStyles.scss';
import { TablePaginationConfig, SorterResult, FilterValue } from 'antd/lib/table/interface';
import { getPromotions } from '../../../redux/actions/backoffice/promotions-actions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ContentComponent } from '../../../Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from '../../../Components/ContentComponent/HeaderTitleComponent';
import { Select, DatePicker, Space, Table } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
    getPromotionsData,
    getPromotionsErrorData,
    getPromotionsLoadingStateData,
    getTotalPromotionsAmountData,
    isUpdateData,
} from '../../../redux/selectors/backoffice/propmotions-selectors';
import { IGameItemData, IPaginationData, IPromotionData } from '../../../helpers/interfaces';
import Search from 'antd/es/input/Search';
import PromotionModal from '../PromotionModalForm/PromotionModal';
import PromotionsColumns from './PromotionsColumns';
import { useNavigate } from 'react-router-dom';
import { localStorageHelper, OperatorGamesUtils } from '../../../utils';

const { RangePicker } = DatePicker;

type SortOrder = 'DESCENDING' | 'ASCENDING';

interface SortConfig {
    sortKey: string;
    sortOrder: SortOrder;
}

const DEFAULT_SORT_CONFIG: SortConfig = { sortKey: 'id', sortOrder: 'DESCENDING' };
const DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE = 20;
const SEARCH_OPTIONS = ['name', 'reference', 'gameId'];
const DEFAULT_PAGINATION = { pageNumber: 1, pageSize: DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE };

interface IProps {
    getPromotions: (params: any) => void;
    data: IPromotionData[];
    totalAmount: number;
    isLoading: boolean;
    error: string;
    isUpdateData: boolean;
}

interface IState {
    filterKey: string;
    filterString: string;
    dateRange: [string, string];
    sortConfig: SortConfig;
    paginationData: IPaginationData;
}

const Promotions: React.FC<IProps> = ({
    getPromotions,
    data,
    totalAmount,
    isLoading,
    isUpdateData,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [state, setState] = useState<{
        filterKey: string;
        filterString: string;
        dateRange: [string, string];
        sortConfig: SortConfig;
        paginationData: IPaginationData;
    }>({
        filterKey: SEARCH_OPTIONS[0],
        filterString: '',
        dateRange: ['', ''],
        sortConfig: DEFAULT_SORT_CONFIG,
        paginationData: DEFAULT_PAGINATION,
    });

    const isUserCanManageCampaigns = localStorageHelper
        .getChainedValue('user.authorities')
        .includes('MANAGE_CAMPAIGNS');

    const columns = PromotionsColumns();

    const handlePagination = useCallback(
        (pageNumber: number, pageSize: number = state.paginationData.pageSize) => {
            setState((prevState) => ({ ...prevState, paginationData: { pageNumber, pageSize } }));
        },
        [state.paginationData.pageSize],
    );

    const requestPromotions = useCallback(() => {
        const { paginationData, sortConfig, filterString, dateRange } = state;
        let { filterKey } = state;
        filterKey = filterKey === 'reference' ? 'externalCampaignId' : filterKey; // Mapping reference to externalCampaignId

        getPromotions({ paginationData, sortConfig, filterString, filterKey, dateRange });
    }, [state, getPromotions]);

    useEffect(() => {
        requestPromotions();
    }, [requestPromotions]);

    useEffect(() => {
        if (isUpdateData) {
            requestPromotions();
        }
    }, [isUpdateData, requestPromotions]);

    const getData = useMemo(() => {
        const allGamesWithFreeRounds = OperatorGamesUtils.getGames().filter(
            (game: IGameItemData) => game.freeRoundsSupported,
        );
        const getGamesColumnContent = (gameIds: number[]) => {
            if (gameIds.length === allGamesWithFreeRounds.length) {
                return t('all_games');
            } else if (gameIds.length === 1) {
                return `${OperatorGamesUtils.getGameNameByID(gameIds[0])}`;
            } else {
                if (gameIds.length % 10 === 1) {
                    return `${gameIds.length} ${t('game')}`;
                } else {
                    return `${gameIds.length} ${t('games')}`;
                }
            }
        };

        return (data || []).map((item) => ({
            ...item,
            key: item.id,
            games: getGamesColumnContent(item.gameIds),
        }));
    }, [data, t]);

    const onRow = useCallback(
        (record: IPromotionData) => ({
            onClick: () => navigate(`/casino/promotions/${record.id}`),
            className: 'cursor-pointer-row',
        }),
        [navigate],
    );

    const onTableChange = useCallback(
        (
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult<IPromotionData> | SorterResult<IPromotionData>[],
        ) => {
            handlePagination(
                pagination.current ?? 1,
                pagination.pageSize ?? DEFAULT_PAGINATION.pageSize,
            );

            let newSortConfig: SortConfig = DEFAULT_SORT_CONFIG;

            if (Array.isArray(sorter) && sorter.length && sorter[0].order) {
                // Handling multiple sorters scenario
                newSortConfig = {
                    sortKey: sorter[0].field ? sorter[0].field.toString() : 'id',
                    sortOrder: sorter[0].order === 'ascend' ? 'ASCENDING' : 'DESCENDING',
                };
            } else if (!Array.isArray(sorter) && sorter.field && sorter.order) {
                // Handling single sorter scenario
                newSortConfig = {
                    sortKey: sorter.field.toString(),
                    sortOrder: sorter.order === 'ascend' ? 'ASCENDING' : 'DESCENDING',
                };
            }

            setState((prevState) => ({ ...prevState, sortConfig: newSortConfig }));
        },
        [handlePagination],
    );

    const handleFilterChange = (key: keyof IState, value: any) => {
        setState((prevState) => ({ ...prevState, [key]: value }));
    };

    const totalWidth: number = columns.reduce(
        (total: number, col: any) => total + (col.width || 0),
        0,
    );

    return (
        <ContentComponent
            header={
                <>
                    <HeaderTitleComponent
                        title={t('promotions')}
                        customBreadcrumbs={<div>{t('all')}</div>}
                    />
                    <Space.Compact
                        className="promotions-search__wrapper"
                        style={{ marginBottom: '8px', width: '100%' }}
                    >
                        <RangePicker
                            style={{ width: '400px' }}
                            className="promotions-search__datepicker"
                            onChange={(date) => {
                                handleFilterChange(
                                    'dateRange',
                                    date ? [date[0], date[1]] : ['', ''],
                                );
                            }}
                            placeholder={[t('StartsFrom'), t('EndsTo')]}
                        />
                        <Select
                            className="promotions-search-selector"
                            value={state.filterKey}
                            onChange={(value) => handleFilterChange('filterKey', value)}
                            style={{ width: '150px' }}
                        >
                            {SEARCH_OPTIONS.map((key) => (
                                <Select.Option key={key} value={key}>
                                    {t(key)}
                                </Select.Option>
                            ))}
                        </Select>
                        <Search
                            placeholder={t('enter_search_value')}
                            value={state.filterString}
                            onChange={(e) => handleFilterChange('filterString', e.target.value)}
                            onSearch={requestPromotions}
                            suffix={
                                <CloseCircleOutlined
                                    className={`promotions-search__clear${
                                        state.filterString.length > 0 ? '__visible' : ''
                                    }`}
                                    onClick={() => handleFilterChange('filterString', '')}
                                />
                            }
                        />
                    </Space.Compact>
                    {isUserCanManageCampaigns && (
                        <div className="promotions-header-tools">
                            <PromotionModal />
                        </div>
                    )}
                </>
            }
            innerContent={
                <Table
                    pagination={{
                        pageSize: state.paginationData.pageSize,
                        current: state.paginationData.pageNumber,
                        total: totalAmount,
                    }}
                    style={{
                        minWidth: '100%',
                        paddingBottom: '16px',
                    }}
                    className={'casino_performance-table'}
                    scroll={{ x: totalWidth }}
                    dataSource={getData}
                    columns={columns}
                    expandable={{
                        showExpandColumn: true,
                        expandedRowRender: (record) => (
                            <>
                                <p>{t('Games in campaign:')}</p>
                                <ul>
                                    {record.gameIds.map((gameId: number) => (
                                        <li key={gameId}>
                                            {OperatorGamesUtils.getGameNameByID(gameId)} | ID: rp-
                                            {gameId}
                                        </li>
                                    ))}
                                </ul>
                                <p>{`${t('Game IDs: ')}${record.gameIds.join(', ')}`}</p>
                            </>
                        ),
                        columnWidth: 0.1,
                    }}
                    loading={isLoading}
                    size={'small'}
                    onRow={onRow}
                    onChange={onTableChange}
                    footer={() => <></>}
                />
            }
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getPromotionsData(state),
    totalAmount: getTotalPromotionsAmountData(state),
    isLoading: getPromotionsLoadingStateData(state),
    error: getPromotionsErrorData(state),
    isUpdateData: isUpdateData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPromotions: (data: any) => dispatch(getPromotions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
