import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './redux/store';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { localStorageHelper } from './utils';
import moment from 'moment-timezone';
import './utils/i18n';
import { createRoot } from 'react-dom/client';

export const reduxStore = configureStore();

const timeZone = localStorageHelper.getChainedValue('user.preferences.timeZone');

if (timeZone) {
    moment.tz.setDefault(timeZone);
}

const root = createRoot(document.getElementById('root')!);

root.render(
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <ReduxProvider store={reduxStore}>
                <App />
            </ReduxProvider>
        </BrowserRouter>
    </I18nextProvider>,
);

serviceWorker.unregister();
