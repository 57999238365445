import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import TableHeaderWrapper from 'Components/Table/TableHeaderWrapper';
import {
    getPlayerPromotionsData,
    getPlayerReport,
} from 'redux/actions/backoffice/player-report-actions';
import { getGameHistoryReport } from 'redux/actions/backoffice/game-history-actions';
import GameHistory from '../Reports/PlayersReport/GameHistory';
import { currencyUtils, dateFormatter, localStorageHelper, OperatorGamesUtils } from 'utils';
import { IPlayerData } from 'redux/reducers/backoffice/player-report-reducer';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { Button, Checkbox, Divider, Empty, Modal, Popover, Radio, Table } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import { Context } from 'App';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';
import {
    getPlayer,
    getPlayerCampaigns,
    getPlayerCurrencyId,
    getPlayerInternalId,
    getPlayerRawData,
} from 'redux/selectors/backoffice/player-report-selectors';
import { getGameHistory } from 'redux/selectors/backoffice/game-history-selectors';
import { getServerError } from 'redux/selectors/backoffice/error-selectors';
import OPAGameHistory from '../Reports/PlayersReport/OPAGameHistory';
import { Link, useParams } from 'react-router-dom';
import { PlayerReportColumns } from './PlayerProfileColumns';
import { SettingOutlined } from '@ant-design/icons';
import { getDateOfLastRefresh } from '../../redux/selectors/user-selectors';

const PlayerProfileComponent: React.FC = () => {
    const dispatch = useDispatch();
    const { login, playerId } = useParams();
    const { t } = useTranslation();
    const playerData = useSelector(getPlayer);
    const playerInternalId = useSelector(getPlayerInternalId);
    const playerRawData = useSelector(getPlayerRawData);
    const campaignsData = useSelector(getPlayerCampaigns);
    const currencyId = useSelector(getPlayerCurrencyId);
    const currencyCode = currencyUtils.getCurrencyDataById(currencyId)?.code;
    const gameHistory = useSelector(getGameHistory);
    const error = useSelector(getServerError);
    const dateOfLastRefresh: Date = useSelector(getDateOfLastRefresh);

    const getGameHistoryData = useCallback(
        (playerData: IPlayerData) => {
            const { playerId, gameSessionId, startId, gameId } = playerData;
            setChosenPlayer(playerData);

            if (OperatorGamesUtils.isGameTypeIsSlot(gameId)) {
                dispatch(getGameHistoryReport({ playerId, gameSessionId, startId }));
            }
        },
        [dispatch],
    );

    const columns = PlayerReportColumns(
        currencyCode,
        getGameHistoryData,
        playerInternalId,
        playerRawData,
    );
    const options = columns.map(({ key }) => ({
        label: t(key),
        value: key,
    }));

    const [tableSize, setTableSize] = useState<'small' | 'middle' | 'large'>('middle');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [checkedList, setCheckedList] = useState(columns.map((item) => item.key));
    const initialDates = localStorageHelper.getChainedValue('user.reports.dates');

    const [fromDate, setFromDate] = useState<Date>(
        initialDates?.fromDate || dateFormatter.getDefaultDate(),
    );
    const [toDate, setToDate] = useState<Date>(
        initialDates?.toDate || dateFormatter.getDefaultDate(false),
    );
    const [chosenPlayer, setChosenPlayer] = useState<IPlayerData | null>(null);

    const visibleColumns = columns.filter((column) => checkedList.includes(column.key));
    const totalWidth = visibleColumns.reduce((total, col) => total + (col.width || 0), 0);

    const calculateTableHeight = useCallback(() => {
        const height = window.innerHeight * 0.8 - 194;
        setTableHeight(height);
    }, []);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const getReport = useCallback(() => {
        dispatch(getPlayerPromotionsData({ fromDate, toDate, login }));

        if (playerId === 'null') {
            dispatch(getPlayerReport({ fromDate, toDate, login }));
        } else {
            dispatch(getPlayerReport({ fromDate, toDate, playerId }));
        }
    }, [fromDate, toDate, playerId, login, dispatch]);

    const parentReportCallback = useCallback(
        (dates: { fromDate: Date; toDate: Date }) => {
            const { fromDate, toDate } = dates;

            localStorageHelper.setChainedValue(`user.reports.playerProfileDates.${login}`, {
                fromDate,
                toDate,
            });

            setFromDate(fromDate);
            setToDate(toDate);
            getReport();
        },
        [login, getReport],
    );

    const handlePageSizeChange = (current: any, size: any) => {
        setPageSize(size);
    };

    const closeGameHistory = useCallback(() => {
        setChosenPlayer(null);
    }, []);

    useEffect(() => {
        getReport();
    }, [getReport]);

    const tryToOpenGameHistoryFromURLParams = useCallback(() => {
        const searchParams = new URLSearchParams(location.search);
        const gameSessionId = searchParams.get('gameSessionId');
        const playerId = searchParams.get('playerId') || playerInternalId;
        const startId = searchParams.get('startId');

        if (gameSessionId && playerId && startId) {
            const chosenPlayer = playerData.find((data: any) => data.startId === Number(startId));

            if (chosenPlayer) {
                getGameHistoryData({ ...chosenPlayer, playerId: Number(playerId) });
            }
        }
    }, [playerData, getGameHistoryData, playerInternalId]);

    useEffect(() => {
        if (playerData) {
            tryToOpenGameHistoryFromURLParams();
        }
    }, [playerData, tryToOpenGameHistoryFromURLParams]);

    const chooseGameHistory = useCallback(() => {
        if (!gameHistory || !gameHistory.length || !chosenPlayer) return null;

        return OperatorGamesUtils.isGameTypeIsSlot(chosenPlayer.gameId) ? (
            <GameHistory
                data={gameHistory}
                summaryData={chosenPlayer}
                currencyId={currencyId}
                onClose={closeGameHistory}
                t={t}
                error={error}
            />
        ) : OperatorGamesUtils.isGameTypeIsMines(chosenPlayer.gameId) ? (
            <OPAGameHistory
                roundId={chosenPlayer?.roundId}
                onClose={closeGameHistory}
                t={t}
                error={error}
            />
        ) : null;
    }, [gameHistory, chosenPlayer, currencyId, t, error, closeGameHistory]);

    const reportData = playerData ? (playerData.length ? playerData : []) : null;

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, [calculateTableHeight]);

    return (
        <ContentComponent
            header={
                <Context.Consumer>
                    {(context) => (
                        <TableHeaderWrapper
                            titleComponent={
                                <HeaderTitleComponent
                                    title={login}
                                    customBreadcrumbs={<div>{t('player')}</div>}
                                />
                            }
                            getReport={getReport}
                            parentReportCallback={parentReportCallback}
                            fromDate={fromDate}
                            toDate={toDate}
                            isShowSelectTime
                            context={context}
                            reportKey={'playerProfile'}
                        />
                    )}
                </Context.Consumer>
            }
            preHeader={
                <div style={{ marginBottom: '8px' }}>
                    {campaignsData && (
                        <Popover
                            placement="right"
                            content={
                                <ol>
                                    {campaignsData.freeRoundCampaigns.map((campaign: any) => (
                                        <li key={campaign.campaignId}>
                                            <Link to={`/casino/promotions/${campaign.campaignId}`}>
                                                {`${t('promotionId')} ${campaign.campaignId} - (${campaign.freeRoundsPlayed}/${campaign.freeRoundsAssigned})`}
                                            </Link>
                                        </li>
                                    ))}
                                </ol>
                            }
                        >
                            <Button>
                                {`${t('free_rounds')} ${campaignsData.freeRoundsPlayed}/${campaignsData.freeRoundsAssigned}`}
                            </Button>
                        </Popover>
                    )}
                </div>
            }
            innerContent={
                <>
                    {error ? (
                        <ErrorComponent error={error} />
                    ) : reportData ? (
                        reportData.length ? (
                            <div className="casino_performance-table-container">
                                <div className={'casino_performance-table-title'}>
                                    <div className="content-wrapper">
                                        <>
                                            <Button
                                                style={{ marginRight: '8px' }}
                                                onClick={toggleModal}
                                            >
                                                <SettingOutlined />
                                            </Button>
                                            Data updated at:{' '}
                                            {dateFormatter.getFormattedDateOfLastRefresh(
                                                dateOfLastRefresh,
                                            )}
                                        </>
                                    </div>
                                    <Modal
                                        title="Table tools"
                                        open={isModalVisible}
                                        onOk={toggleModal}
                                        onCancel={toggleModal}
                                    >
                                        <Divider>Columns displayed</Divider>
                                        <Checkbox.Group
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                marginBottom: '20px',
                                            }}
                                            options={options}
                                            value={checkedList}
                                            onChange={(checkedValues) => {
                                                setCheckedList(checkedValues.map(String));
                                            }}
                                        />
                                        <Divider>Columns displayed</Divider>
                                        <div style={{ marginBottom: 16 }}>
                                            <span>Change Table Cell Size: </span>
                                            <Radio.Group
                                                value={tableSize}
                                                onChange={(e) => setTableSize(e.target.value)}
                                            >
                                                <Radio.Button value="large">Large</Radio.Button>
                                                <Radio.Button value="middle">Middle</Radio.Button>
                                                <Radio.Button value="small">Small</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </Modal>
                                </div>
                                <Table
                                    style={{
                                        minWidth: '100%',
                                        paddingBottom: '16px',
                                    }}
                                    className={'casino_performance-table'}
                                    dataSource={reportData}
                                    scroll={{ x: totalWidth, y: tableHeight }}
                                    columns={visibleColumns}
                                    pagination={{
                                        pageSize,
                                        position: ['topRight'],
                                        pageSizeOptions: [10, 20, 50, 100],
                                        onShowSizeChange: handlePageSizeChange,
                                    }}
                                    size={tableSize}
                                    rowKey={(record) => `${record.roundId.toString()}`}
                                    footer={() => <></>}
                                />
                            </div>
                        ) : (
                            <Empty description={t('no_data')} />
                        )
                    ) : (
                        <Loader style={{ height: '90vh' }} />
                    )}
                    {chooseGameHistory()}
                </>
            }
            reportKey={'playerProfile'}
        />
    );
};

export default PlayerProfileComponent;
