import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tag, Tooltip } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { FreeRoundType, localStorageHelper } from 'utils';

const PromotionsColumns = () => {
    const { t } = useTranslation();
    const isPrimaryFlow = localStorageHelper.getChainedValue(
        'user.brandFeatures.freeRoundsType',
    ) === FreeRoundType.PRIMARY_FLOW;

    const defaultColumns = [
        {
            title: t('timezone'),
            dataIndex: 'timezone',
            key: 'timezone',
            width: 165,
        },
        {
            title: t('startAt'),
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            width: 165,
        },
        {
            title: t('endAt'),
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
            width: 165,
        },
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            width: 165,

        },
        {
            title: t('reference'),
            dataIndex: 'externalCampaignId',
            key: 'externalCampaignId',
            sorter: true,
            width: 165,

        },
        Table.EXPAND_COLUMN,
        {
            title: t('games'),
            dataIndex: 'games',
            key: 'games',
            width: 165,
        },
        {
            title: t('currencyCode'),
            dataIndex: 'currencyCode',
            key: 'currencyCode',
            width: 165,
        },
        {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 165,
            render: (text: any, record: any) => {
                const colorToStatus: any = {
                    DISABLED: 'red',
                    ONGOING: 'green',
                    UPCOMING: 'blue',
                    ENDED: 'gold',
                };

                return (
                    <Tag color={colorToStatus[record.status]}>{t(record.status.toLowerCase())}</Tag>
                );
            },
        },
        {
            title: t('strategy'),
            dataIndex: 'strategy',
            key: 'strategy',
            width: 165,
        },
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            width: 165,
        },
        {
            title: (
                <div style={{ display: 'flex' }}>
                    {t('playing/eligible_player')}{' '}
                    <Tooltip
                        title={t(
                            'for_the_players_list_type_only_playing_players_number_is_reflected',
                        )}
                    >
                        <InfoOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'eligiblePlayersNumber',
            width: 165,
            key: 'eligiblePlayersNumber',
            render: (text: any, record: any) => {
                return `${record.participatingPlayersNumber}${
                    record.strategy !== 'PLAYER_LIST' ? `/${record.eligiblePlayersNumber}` : ''
                }`;
            },
        },
        {
            title: (
                <div style={{ display: 'flex' }}>
                    {t('rounds_played/given')}{' '}
                    <Tooltip
                        title={t(
                            'for_the_players_list_type_only_the_number_of_played_rounds_is_reflected',
                        )}
                    >
                        <InfoOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'roundsPlayed',
            width: 165,
            key: 'roundsPlayed',
            render: (text: any, record: any) => {
                return `${Number(record.roundsPlayed)}${
                    record.strategy !== 'PLAYER_LIST'
                        ? `/${record.betNumber * record.eligiblePlayersNumber}`
                        : ''
                }`;
            },
        },
        {
            title: t('brandId'),
            dataIndex: 'brandId',
            key: 'brandId',
            width: 165,
        },
        {
            title: t('disabledAt'),
            dataIndex: 'disabledAt',
            key: 'disabledAt',
            width: 165,
        },
    ];

    const primaryFLowRoundsPlayedColumn =
    {
        title: (
            <div style={{ display: 'flex' }}>
                {t('playing/eligible_player')}{' '}
                <Tooltip
                    title={t(
                        'for_the_players_list_type_only_playing_players_number_is_reflected',
                    )}
                >
                    <InfoOutlined />
                </Tooltip>
            </div>
        ),
        dataIndex: 'eligiblePlayersNumber',
        key: 'eligiblePlayersNumber',
        width: 165,
        render: (text: any, record: any) => {
            return `${record.participatingPlayersNumber}${
                record.strategy !== 'PLAYER_LIST' ? `/${record.eligiblePlayersNumber}` : ''
            }`;
        },
    };

    const secondaryFlowRoundsPlayedColumn = {
        title: t('played'),
        dataIndex: 'playedPlayersNumber',
        key: 'playedPlayersNumber',
        width: 165,
    }

    defaultColumns.splice(9, 0, isPrimaryFlow ? primaryFLowRoundsPlayedColumn : secondaryFlowRoundsPlayedColumn);
    return defaultColumns;
};

export default PromotionsColumns;
