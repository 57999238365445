const idToCode: any = {
    '8': 'ALL',
    '12': 'DZD',
    '32': 'ARS',
    '36': 'AUD',
    '44': 'BSD',
    '48': 'BHD',
    '50': 'BDT',
    '51': 'AMD',
    '52': 'BBD',
    '60': 'BMD',
    '64': 'BTN',
    '68': 'BOB',
    '72': 'BWP',
    '84': 'BZD',
    '90': 'SBD',
    '96': 'BND',
    '104': 'MMK',
    '108': 'BIF',
    '116': 'KHR',
    '124': 'CAD',
    '132': 'CVE',
    '136': 'KYD',
    '144': 'LKR',
    '152': 'CLP',
    '156': 'CNY',
    '170': 'COP',
    '174': 'KMF',
    '188': 'CRC',
    '191': 'HRK',
    '192': 'CUP',
    '203': 'CZK',
    '208': 'DKK',
    '214': 'DOP',
    '222': 'SVC',
    '230': 'ETB',
    '232': 'ERN',
    '238': 'FKP',
    '242': 'FJD',
    '262': 'DJF',
    '270': 'GMD',
    '292': 'GIP',
    '320': 'GTQ',
    '324': 'GNF',
    '328': 'GYD',
    '332': 'HTG',
    '340': 'HNL',
    '344': 'HKD',
    '348': 'HUF',
    '352': 'ISK',
    '356': 'INR',
    '360': 'IDR',
    '364': 'IRR',
    '368': 'IQD',
    '376': 'ILS',
    '388': 'JMD',
    '392': 'JPY',
    '398': 'KZT',
    '400': 'JOD',
    '404': 'KES',
    '408': 'KPW',
    '410': 'KRW',
    '414': 'KWD',
    '417': 'KGS',
    '418': 'LAK',
    '422': 'LBP',
    '426': 'LSL',
    '430': 'LRD',
    '434': 'LYD',
    '446': 'MOP',
    '454': 'MWK',
    '458': 'MYR',
    '462': 'MVR',
    '478': 'MRO',
    '480': 'MUR',
    '484': 'MXN',
    '496': 'MNT',
    '498': 'MDL',
    '504': 'MAD',
    '512': 'OMR',
    '516': 'NAD',
    '524': 'NPR',
    '532': 'ANG',
    '533': 'AWG',
    '548': 'VUV',
    '554': 'NZD',
    '558': 'NIO',
    '566': 'NGN',
    '578': 'NOK',
    '586': 'PKR',
    '590': 'PAB',
    '598': 'PGK',
    '600': 'PYG',
    '604': 'PEN',
    '608': 'PHP',
    '634': 'QAR',
    '643': 'RUB',
    '646': 'RWF',
    '654': 'SHP',
    '678': 'STD',
    '682': 'SAR',
    '690': 'SCR',
    '694': 'SLL',
    '702': 'SGD',
    '704': 'VND',
    '706': 'SOS',
    '710': 'ZAR',
    '728': 'SSP',
    '748': 'SZL',
    '752': 'SEK',
    '756': 'CHF',
    '760': 'SYP',
    '764': 'THB',
    '776': 'TOP',
    '780': 'TTD',
    '784': 'AED',
    '788': 'TND',
    '800': 'UGX',
    '807': 'MKD',
    '818': 'EGP',
    '826': 'GBP',
    '834': 'TZS',
    '840': 'USD',
    '858': 'UYU',
    '860': 'UZS',
    '882': 'WST',
    '886': 'YER',
    '901': 'TWD',
    '928': 'VES',
    '931': 'CUC',
    '932': 'ZWL',
    '933': 'BYN',
    '934': 'TMT',
    '936': 'GHS',
    '938': 'SDG',
    '941': 'RSD',
    '943': 'MZN',
    '944': 'AZN',
    '946': 'RON',
    '949': 'TRY',
    '950': 'XAF',
    '951': 'XCD',
    '952': 'XOF',
    '953': 'XPF',
    '967': 'ZMW',
    '968': 'SRD',
    '969': 'MGA',
    '971': 'AFN',
    '972': 'TJS',
    '973': 'AOA',
    '975': 'BGN',
    '976': 'CDF',
    '977': 'BAM',
    '978': 'EUR',
    '980': 'UAH',
    '981': 'GEL',
    '985': 'PLN',
    '986': 'BRL',
    '990': 'CLF',
    '1501': 'GC',
    '1502': 'SC',
    '1503': 'CC',
    '1504': 'TEUR',
    '1505': 'BT.',
    '1506': 'BK.',
    '1507': 'FICO',
    '1508': 'TICO',
    '1509': 'VC$',
    '1510': 'SC.',
    '1511': 'GC.',
    '1512': 'FUN',
    '2001': 'FTN'
};

const codeToId: any = {
    ALL: 8,
    DZD: 12,
    ARS: 32,
    AUD: 36,
    BSD: 44,
    BHD: 48,
    BDT: 50,
    AMD: 51,
    BBD: 52,
    BMD: 60,
    BTN: 64,
    BOB: 68,
    BWP: 72,
    BZD: 84,
    SBD: 90,
    BND: 96,
    MMK: 104,
    BIF: 108,
    KHR: 116,
    CAD: 124,
    CVE: 132,
    KYD: 136,
    LKR: 144,
    CLP: 152,
    CNY: 156,
    COP: 170,
    KMF: 174,
    CRC: 188,
    HRK: 191,
    CUP: 192,
    CZK: 203,
    DKK: 208,
    DOP: 214,
    SVC: 222,
    ETB: 230,
    ERN: 232,
    FKP: 238,
    FJD: 242,
    DJF: 262,
    GMD: 270,
    GIP: 292,
    GTQ: 320,
    GNF: 324,
    GYD: 328,
    HTG: 332,
    HNL: 340,
    HKD: 344,
    HUF: 348,
    ISK: 352,
    INR: 356,
    IDR: 360,
    IRR: 364,
    IQD: 368,
    ILS: 376,
    JMD: 388,
    JPY: 392,
    KZT: 398,
    JOD: 400,
    KES: 404,
    KPW: 408,
    KRW: 410,
    KWD: 414,
    KGS: 417,
    LAK: 418,
    LBP: 422,
    LSL: 426,
    LRD: 430,
    LYD: 434,
    MOP: 446,
    MWK: 454,
    MYR: 458,
    MVR: 462,
    MRO: 478,
    MUR: 480,
    MXN: 484,
    MNT: 496,
    MDL: 498,
    MAD: 504,
    OMR: 512,
    NAD: 516,
    NPR: 524,
    ANG: 532,
    AWG: 533,
    VUV: 548,
    NZD: 554,
    NIO: 558,
    NGN: 566,
    NOK: 578,
    PKR: 586,
    PAB: 590,
    PGK: 598,
    PYG: 600,
    PEN: 604,
    PHP: 608,
    QAR: 634,
    RUB: 643,
    RWF: 646,
    SHP: 654,
    STD: 678,
    SAR: 682,
    SCR: 690,
    SLL: 694,
    SGD: 702,
    VND: 704,
    SOS: 706,
    ZAR: 710,
    SSP: 728,
    SZL: 748,
    SEK: 752,
    CHF: 756,
    SYP: 760,
    THB: 764,
    TOP: 776,
    TTD: 780,
    AED: 784,
    TND: 788,
    UGX: 800,
    MKD: 807,
    EGP: 818,
    GBP: 826,
    TZS: 834,
    USD: 840,
    UYU: 858,
    UZS: 860,
    WST: 882,
    YER: 886,
    TWD: 901,
    VES: 928,
    CUC: 931,
    ZWL: 932,
    BYN: 933,
    TMT: 934,
    GHS: 936,
    SDG: 938,
    RSD: 941,
    MZN: 943,
    AZN: 944,
    RON: 946,
    TRY: 949,
    XAF: 950,
    XCD: 951,
    XOF: 952,
    XPF: 953,
    ZMW: 967,
    SRD: 968,
    MGA: 969,
    AFN: 971,
    TJS: 972,
    AOA: 973,
    BGN: 975,
    CDF: 976,
    BAM: 977,
    EUR: 978,
    UAH: 980,
    GEL: 981,
    PLN: 985,
    BRL: 986,
    CLF: 990,
    GC: 1501,
    SC: 1502,
    CC: 1503,
    TEUR: 1504,
    BT: 1505,
    BK: 1506,
    FICO: 1507,
    TICO: 1508,
    VC$: 1509,
    'SC.': 1510,
    'GC.': 1511,
    FUN: 1512,
    FTN: 2001,
};

export const currencyNameConverter = {
    idToCode: (id: string) => {
        return idToCode[id];
    },

    codeToId: (code: string) => {
        return codeToId[code];
    },
};
