import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import * as i18next from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import { Loader } from 'Components/Loader/Loader';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { Link } from 'react-router-dom';
import { Select } from 'antd';
import {
    addCurrencyDefaultConfigRequest,
    getGameUnconfiguredCurrenciesRequest,
} from 'redux/actions/configProvider/currencies-actions';
import { currencyUtils } from 'utils';
import { Footer } from 'Components/Footer/Footer';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import {
    getIsNewConfigAdded,
    getUnconfiguredCurrencies,
    isAddingDefaultCurrencyFormLoading,
} from 'redux/selectors/configProvider/currency-selectors';
import { ChangeEvent } from 'react';
import { withRouter } from '../../../../helpers/HOCs/withRouter';

const { Option } = Select;

interface IProps {
    getUnconfiguredCurrencies: Function;
    unconfiguredCurrencies: number[];
    addCurrencyDefaultConfig: Function;
    isNewConfigAdded: boolean;
    loading: boolean;
    adding: boolean;
    t: i18next.TFunction;
    navigate: any;
}

interface IState {
    currencyId?: number;
    currencyConfig: string;
    error: string;
}

class AddDefaultGameConfig extends React.Component<IProps & WithTranslation> {
    state: IState = {
        currencyConfig: '',
        error: '',
    };

    onSelectChange = (value: number) => this.setState({ currencyId: value });

    onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;

        this.setState({ currencyConfig: value });
    };

    handleSubmit = () => {
        const error = this.getValidationMessage();

        if (error) {
            this.setState({ error });
        } else {
            const { currencyId } = this.state;
            const gameConfig = this.getConfigToSend();

            this.props.addCurrencyDefaultConfig({ currencyId, gameConfig });
        }
    };

    getConfigToSend = () => {
        const configToSend: any = {};
        const parsedConfig = JSON.parse(`{${this.state.currencyConfig}}`);

        for (const key in parsedConfig) {
            if (Object.prototype.hasOwnProperty.call(parsedConfig, key) && parsedConfig[key]) {
                configToSend[key.toString()] = JSON.stringify(parsedConfig[key]);
            }
        }

        return configToSend;
    };

    getValidationMessage = () => {
        let validationMessage = '';

        try {
            JSON.parse(`{${this.state.currencyConfig}}`);
        } catch (e) {
            validationMessage = 'json_not_valid';
        }

        return validationMessage;
    };

    componentDidMount = () => {
        this.props.getUnconfiguredCurrencies();
    };

    componentDidUpdate(prevProps: IProps) {
        if (this.props.isNewConfigAdded && !prevProps.isNewConfigAdded) {
            this.props.navigate(`/config-provider/currencies`);
        }
    }

    getUnconfiguredCurrencies = () => {
        const { unconfiguredCurrencies, t } = this.props;
        const { currencyId } = this.state;

        return (
            <div className="item">
                <label>
                    <span className="red-color">* </span>
                    {t('choose_currency')}
                </label>
                <Select
                    className="currencies-names-selector"
                    showSearch
                    placeholder={
                        currencyId
                            ? currencyUtils.getCurrencyDataById(currencyId).name
                            : t('choose_currency')
                    }
                    onChange={(e: string) =>
                        this.onSelectChange(currencyUtils.getCurrencyDataByName(e).id)
                    }
                >
                    {unconfiguredCurrencies?.map((currencyId: number) => {
                        const currencyData = currencyUtils.getCurrencyDataById(currencyId);

                        return (
                            <Option key={currencyData.name} value={currencyData.name}>
                                {currencyData.name}
                            </Option>
                        );
                    })}
                </Select>
            </div>
        );
    };

    render = () => {
        const { t, loading, adding } = this.props;
        const { currencyId, currencyConfig, error } = this.state;

        return (
            <ContentComponent
                withFooter
                header={
                    <HeaderTitleComponent
                        title={t('new_default_currency_configuration')}
                        customBreadcrumbs={<div>{t('currencies')}</div>}
                    />
                }
                innerContent={
                    adding || loading ? (
                        <Loader />
                    ) : (
                        <>
                            <ItemComponentWrapper customClass="default-currency-picker">
                                {this.getUnconfiguredCurrencies()}
                                <div className="item">
                                    <div>
                                        <span className="red-color">* </span>
                                        {t('default_configuration')}
                                    </div>
                                    <TextArea
                                        style={{ backgroundColor: '#0B1022' }}
                                        value={currencyConfig}
                                        rows={10}
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                            this.onChange(e)
                                        }
                                        disabled={!currencyId}
                                    />
                                    <p className="error">{t(error)}</p>
                                </div>
                            </ItemComponentWrapper>
                            <Footer>
                                <Link to={`/config-provider/currencies`}>
                                    <SecondaryButton>{t('cancel')}</SecondaryButton>
                                </Link>
                                <MainButton
                                    disabled={!currencyConfig.length}
                                    onClick={() => currencyConfig.length && this.handleSubmit()}
                                >
                                    {t('save')}
                                </MainButton>
                            </Footer>
                        </>
                    )
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    unconfiguredCurrencies: getUnconfiguredCurrencies(state),
    loading: isAddingDefaultCurrencyFormLoading(state),
    isNewConfigAdded: getIsNewConfigAdded(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUnconfiguredCurrencies: () => dispatch(getGameUnconfiguredCurrenciesRequest()),
    addCurrencyDefaultConfig: (data: any) => dispatch(addCurrencyDefaultConfigRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(AddDefaultGameConfig)));
