import * as React from 'react';
import './ContentComponent.scss';
import { BreadcrumbComponent } from '../BreadcrumbComponent/BreadcrumbComponent';

interface IProps {
    title: any;
    content?: JSX.Element;
    customBreadcrumbs?: JSX.Element;
}

export const HeaderTitleComponent = (props: IProps) => {
    const { title, content, customBreadcrumbs } = props;

    return (
        <>
            <h1 className="content-title" id="content_title">
                <span className="title-span">
                    {
                        <BreadcrumbComponent
                            customBreadcrumbs={customBreadcrumbs}
                        />
                    }
                </span>
                {title}
            </h1>
            {content && <div className="usersList__buttons">{content}</div>}
        </>
    );
};
