export const SearchTypes = {
    SEARCH_PLAYERS_REQUEST: 'SEARCH_PLAYERS',
    SEARCH_PLAYERS_SUCCESS: 'SEARCH_PLAYERS_SUCCESS',
    SEARCH_PLAYERS_FAILED: 'SEARCH_PLAYERS_FAILED',
    REMOVE_SEARCH: 'REMOVE_SEARCH',
};

export const searchPlayers = (payload?: any) => ({
    type: SearchTypes.SEARCH_PLAYERS_REQUEST,
    payload,
});
export const searchPlayersSuccess = (payload?: any) => ({
    type: SearchTypes.SEARCH_PLAYERS_SUCCESS,
    payload,
});
export const removeSearchFromStorage = (payload?: any) => ({
    type: SearchTypes.REMOVE_SEARCH,
    payload,
});
