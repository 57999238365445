import * as React from 'react';
import './SearchComponent.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { componentShouldBeDisplayed, localStorageHelper } from 'utils';
import { CloseOutlined } from '@ant-design/icons/lib';
import { removeSearchFromStorage, searchPlayers } from 'redux/actions/backoffice/search-actions';
import { getSearchString } from 'redux/selectors/backoffice/search-selectors';
import { withRouter } from '../../helpers/HOCs/withRouter';
import { Input } from 'antd';

const { Search } = Input;

interface ISearchString {
    searchString: string;
}

interface IProps extends ISearchString {
    getPlayersReportFromSearch: Function;
    removeSearchFromStorage: Function;
    searchString: string;
    customClass?: string;
    navigate: any;
}

interface IState {
    currentSearchString: string | null;
}

class SearchComponent extends React.Component<WithTranslation & IProps, IState> {
    state: IState = {
        currentSearchString: null,
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        this.setState(() => ({ currentSearchString: value }));
    };

    handleClear = () => {
        this.props.removeSearchFromStorage();

        this.setState(() => ({ currentSearchString: null }));
    };

    handleSubmit = () => {
        const { currentSearchString } = this.state;

        if (currentSearchString) {
            this.props.getPlayersReportFromSearch({
                searchString: currentSearchString.split(' ').join(''),
            });
        }

        this.props.navigate(`/casino/search-result`);
    };

    componentWillUnmount(): void {
        const { pathname } = window.location;
        const searchString = localStorageHelper.getChainedValue('user.search.currentSearchString');
        const isStringInStorage = searchString && searchString.length > 0;
        const isNotOnResultsPage =
            pathname.split('/')[2] !== 'search-result' && pathname.split('/')[2] !== 'players';

        if (isNotOnResultsPage && isStringInStorage) {
            this.props.removeSearchFromStorage();
        }
    }

    render = () => {
        const { searchString, t } = this.props;
        const { currentSearchString } = this.state;
        const isFieldEmpty = currentSearchString === null || currentSearchString === '';

        return (
            componentShouldBeDisplayed('searchField') && (
                <Search
                    className="main-header__search-field"
                    type="text"
                    value={isFieldEmpty ? searchString : currentSearchString}
                    placeholder={t('search_players_by_login')}
                    id="search"
                    onChange={this.handleChange}
                    onSearch={this.handleSubmit}
                    maxLength={100}
                    suffix={
                        <CloseOutlined
                            onClick={isFieldEmpty ? undefined : this.handleClear}
                            style={{ display: isFieldEmpty ? 'none' : 'inline' }}
                        />
                    }
                />
            )
        );
    };
}

const mapStateToProps = (state: any) => ({
    searchString: getSearchString(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPlayersReportFromSearch: (data: ISearchString) => dispatch(searchPlayers(data)),
    removeSearchFromStorage: () => dispatch(removeSearchFromStorage()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(SearchComponent)));
