import { localStorageHelper, searchByName } from 'utils';
import { IAction } from 'helpers/interfaces';
import { SearchTypes } from 'redux/actions/backoffice/search-actions';
import { UserTypes } from 'redux/actions/user-actions';

interface ISearchData {
    searchString: string;
    isSearching: boolean;
    searchResult?: any;
    error?: any;
}

export const defaultSearchState: ISearchData = {
    searchString:
        localStorageHelper.getChainedValue('user.search.currentSearchString') ||
        '',
    isSearching: false,
};

const searchReducer = (state = defaultSearchState, actions: IAction) => {
    switch (actions.type) {
        case SearchTypes.SEARCH_PLAYERS_REQUEST: {
            const { searchString } = actions.payload;

            return {
                ...defaultSearchState,
                searchString,
                isSearching: true,
            };
        }

        case SearchTypes.SEARCH_PLAYERS_SUCCESS: {
            const { searchString, response } = actions.payload;
            const result = searchByName(searchString, response);

            localStorageHelper
                .setChainedValue(
                    'user.search.currentSearchString',
                    searchString,
                )
                .setChainedValue('user.search.searchResult', result);

            return {
                searchString,
                isSearching: false,
                searchResult: result,
            };
        }

        case SearchTypes.SEARCH_PLAYERS_FAILED: {
            return {
                ...defaultSearchState,
                error: true,
            };
        }

        case SearchTypes.REMOVE_SEARCH: {
            localStorageHelper
                .setChainedValue('user.search.currentSearchString', '')
                .setChainedValue('user.search.searchResult', []);

            return defaultSearchState;
        }

        case UserTypes.LOGOUT: {
            return defaultSearchState;
        }

        default:
            return state;
    }
};

export default searchReducer;
