import { localStorageHelper, Messages } from 'utils';
import { IAction } from 'helpers/interfaces';
import { UserTypes } from 'redux/actions/user-actions';
import { errorHandler } from 'helpers/errorHandler';
import moment from 'moment';
import { GeneralConfigProviderTypes } from '../../actions/configProvider/general-actions';
import { addToPendingQueue, updatePendingQueue } from './userReducerHelper';
import i18next from 'i18next';
const PASSWORD_CHANGE_REQUIRED_CODE = 9;

export interface IUserState {
    name?: string;
    email?: string;
    role?: string;
    pendingQueue: string[];
    authorities: string[];
    isLoggingOut: boolean;
    isAuthorised: boolean;
    isLoading: boolean;
    searchString: string;
    shouldDisplayTestData: boolean;
    error?: string;
    newName?: string;
    isNameChanged?: boolean | null;
    isPasswordChanged?: boolean | null;
    isPreferencesChanged?: boolean | null;
    preferencesUpdateError?: string | null;
    passwordChangeRequired?: boolean;
    defaultPasswordChanged?: boolean;
    dateOfLastRefresh?: Date;
}

const newSearchString = localStorageHelper.getChainedValue('user.search.currentSearchString') || '';
const isAuthorized = Boolean(localStorageHelper.get('isAuthorized'));

const defaultPreferences = {
    isNameChanged: null,
    isPasswordChanged: null,
    isPreferencesChanged: null,
    preferencesUpdateError: null,
};

export const user: IUserState = {
    name: '',
    email: '',
    role: '',
    authorities: [],
    pendingQueue: [],
    isLoggingOut: false,
    isAuthorised: isAuthorized,
    isLoading: false,
    shouldDisplayTestData: false,
    passwordChangeRequired: false,
    defaultPasswordChanged: false,
    searchString: newSearchString,
    ...defaultPreferences,
};

const userReducer = (state: IUserState = { ...user }, actions: IAction) => {
    switch (actions.type) {
        case UserTypes.LOGIN_REQUEST: {
            return {
                ...state,
                error: '',
                isLoading: true,
                email: actions.payload.email,
            };
        }

        case UserTypes.LOGIN_FAILED: {
            if (actions.payload.response.data.error.code === PASSWORD_CHANGE_REQUIRED_CODE) {
                return {
                    ...state,
                    isLoading: false,
                    passwordChangeRequired: true,
                };
            }

            return { ...state, isLoading: false };
        }
        case UserTypes.LOGOUT: {
            localStorageHelper.remove(
                'headers',
                'timestamp',
                'user',
                'isAuthorized',
                'gamesList',
                'authorization',
            );
            return { ...user, isAuthorised: false, isLoggingOut: true };
        }

        case UserTypes.LOGIN_SUCCESS: {
            localStorageHelper.set('isAuthorized', true);

            return { ...state, isLoggingOut: false };
        }

        case UserTypes.DATE_OF_LAST_REFRESH_SUCCESS: {
            return { ...state, dateOfLastRefresh: actions.payload };
        }

        case UserTypes.CHANGE_DEFAULT_PASSWORD_REQUEST: {
            return { ...state, isLoading: true };
        }

        case UserTypes.CHANGE_DEFAULT_PASSWORD_SUCCESS: {
            Messages.success(i18next.t('default_password_successfully_changed'));

            return {
                ...state,
                isLoading: false,
                passwordChangeRequired: false,
                defaultPasswordChanged: true,
            };
        }

        case UserTypes.CHANGE_DEFAULT_PASSWORD_FAILED: {
            return {
                ...state,
                isLoading: false,
                passwordChangeRequired: false,
            };
        }

        case UserTypes.INFO_SUCCESS: {
            return { ...state, ...actions.payload };
        }

        case UserTypes.UPDATE_PREFERENCES_REQUEST: {
            return { ...state, ...defaultPreferences };
        }

        case UserTypes.UPDATE_PREFERENCES_SUCCESS: {
            localStorageHelper.setChainedValue('user.preferences', actions.payload);

            return { ...state, isPreferencesChanged: true };
        }

        case UserTypes.UPDATE_PREFERENCES_FAILED: {
            const preferencesUpdateError = errorHandler.getErrorText(actions.payload);

            return {
                ...state,
                preferencesUpdateError,
                isPreferencesChanged: false,
            };
        }

        case UserTypes.CHANGE_NAME_REQUEST:
        case UserTypes.CHANGE_PASSWORD_REQUEST: {
            return {
                ...state,
                ...defaultPreferences,
                newName: actions.payload.name,
            };
        }

        case UserTypes.CHANGE_NAME_SUCCESS: {
            localStorageHelper.setChainedValue('user.name', state.newName);

            return { ...state, isNameChanged: true };
        }

        case UserTypes.CHANGE_NAME_FAILED: {
            const preferencesUpdateError = 'user_name_was_not_changed';

            return { ...state, preferencesUpdateError, isNameChanged: false };
        }

        case UserTypes.CHANGE_PASSWORD_SUCCESS: {
            return { ...state, isPasswordChanged: true };
        }

        case UserTypes.CHANGE_PASSWORD_FAILED: {
            // TODO: will get error from errorHandler when server finished with errors
            const preferencesUpdateError = 'change_password_failed';

            return {
                ...state,
                preferencesUpdateError,
                isPasswordChanged: false,
            };
        }

        case UserTypes.REMOVE_IS_ERROR_STATE: {
            return { ...state, error: '' };
        }

        case GeneralConfigProviderTypes.GET_DEFAULT_GAME_CONFIGS_REQUEST: {
            return addToPendingQueue(state, actions);
        }

        case UserTypes.GET_ALL_CURRENCIES_REQUEST: {
            return addToPendingQueue(state, actions);
        }

        case GeneralConfigProviderTypes.GET_ALL_GAMES_REQUEST: {
            return addToPendingQueue(state, actions);
        }

        case UserTypes.TIME_ZONE_REQUEST: {
            return addToPendingQueue(state, actions);
        }

        case UserTypes.GAMES_BY_OPERATOR_REQUEST: {
            return addToPendingQueue(state, actions);
        }

        case GeneralConfigProviderTypes.GET_DEFAULT_GAME_CONFIGS_SUCCESS: {
            return updatePendingQueue(state, actions);
        }

        case UserTypes.ALL_CURRENCY_SUCCESS: {
            localStorageHelper.setChainedValue('user.allCurrencies', actions.payload.response);

            return updatePendingQueue(state, actions);
        }

        case UserTypes.GET_ALL_CURRENCIES_BY_BRAND_SUCCESS: {
            localStorageHelper.setChainedValue('user.currenciesByBrand', actions.payload.response);

            return updatePendingQueue(state, actions);
        }

        case GeneralConfigProviderTypes.GET_ALL_GAMES_SUCCESS: {
            return updatePendingQueue(state, actions);
        }

        case UserTypes.TIME_ZONE_SUCCESS: {
            localStorage.setItem('timestamp', moment().format());

            return updatePendingQueue(state, actions);
        }

        case UserTypes.GAMES_BY_OPERATOR_SUCCESS: {
            return updatePendingQueue(state, actions);
        }

        case UserTypes.GET_ALL_COUNTRIES_SUCCESS: {
            return updatePendingQueue(state, actions);
        }

        case UserTypes.CHANGE_STATE_DISPLAYING_TEST_DATA: {
            localStorageHelper.setChainedValue('user.preferences.displayTestData', actions.payload);

            return { ...state, shouldDisplayTestData: actions.payload };
        }
        case UserTypes.SHOW_ERROR_NOTIFICATION: {
            Messages.error(errorHandler.getErrorText(actions.payload));

            return { ...state };
        }

        default:
            return state;
    }
};

export default userReducer;
